'use client'
import { useState } from 'react'
import { FaSearch, FaHome, FaBuilding } from 'react-icons/fa'
import { MdSell, MdVilla } from 'react-icons/md'
import { FaShop } from "react-icons/fa6";
import { AiFillLayout } from 'react-icons/ai'
import Link from 'next/link'
import { saleTypes } from '@constants/saleTypes';
import tailwindConfig from '@tailwind.config'
import HeroSearchBts from '@components/Search/HeroSearchBts'
import LinkComponent from '@components/LinkComponent'
import { searchTypes } from '@constants/searchTypes';




const FrontSearchBar = ({ btsList, slogan, dictionary, lang }) => {
    const [sale, setSale] = useState(searchTypes[lang][0])
    // console.log(slogan)
    let [category, setCategory] = useState('')

    const [selectedBts, setSelectedBts] = useState(btsList[0])

    const handleUpdateParams = (e) => {
        setSelectedBts(e)
    }

    return (
        <div className='absolute w-full z-50 sm:bottom-8 bottom-1/3  flex flex-col justify-center items-center'>
            <div className='mb-5'>
                <h1 className='text-light md:text-[60px] text-xl font-bold'>{slogan?.[lang] ? slogan[lang] : ''}</h1>
            </div>
            <div className="block md:hidden mt-3">
                <div className='flex flex-col w-[320px] bg-light rounded-md pt-1 shadow-[0_3px_10px_rgb(0,0,0,0.2)]'>
                    <div className='w-full flex justify-center border-forth border-b-[1px]'>
                        <h1 className='text-maincolor font-semibold'>{dictionary.whatAreYouLookingFor}</h1>
                    </div>
                    <div className='flex flex-row w-full justify-between'>
                        <Link href={`/properties?saleType=${sale.value}&category=house&filtertab=true`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-20 hover:font-bold hover:drop-shadow-md rounded-lg'><MdVilla size={32} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor text-xs mt-1'>{dictionary.house}</span></Link>
                        <Link href={`/properties?saleType=${sale.value}&category=land&filtertab=true`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-20 hover:font-bold hover:drop-shadow-md rounded-lg'><AiFillLayout className='hover:secondary' size={32} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor text-xs mt-1'>{dictionary.land}</span></Link>
                        <Link href={`/properties?saleType=${sale.value}&category=condo&filtertab=true`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-20 hover:font-bold hover:drop-shadow-md rounded-lg'><FaBuilding size={32} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor text-xs mt-1'>{dictionary.condo}</span></Link>
                        <Link href={`/properties?saleType=${sale.value}&category=commercial&filtertab=true`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-20 hover:font-bold hover:drop-shadow-md rounded-lg'><FaShop size={32} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor text-xs mt-1'>{dictionary.commercial}</span></Link>
                    </div>
                </div>
            </div>
            <div className="hidden md:block mt-4">
                <div className='flex flex-col w-[640px] bg-light rounded-md p-4'>
                    <div className='flex flex-row justify-between mb-2'>
                        <div className='flex w-full justify-center items-center hover:drop-shadow-md'>
                            <span className='font-bold cursor-pointer text-center text-maincolor'>{dictionary.quickSearch}</span>
                        </div>
                        <div className='flex w-full justify-center items-center border-x-[1px] border-forth hover:font-bold hover:drop-shadow-md'>
                            <LinkComponent href={'/properties?filtertab=true'} className=' text-maincolor'>{dictionary.search}</LinkComponent>
                        </div>
                        <div className='flex w-full justify-center items-center font-semibold'>
                            <Link className='text-third' href={'#personalized-service'} >{dictionary.freeHomeSearch}</Link>
                        </div>
                    </div>
                    <div className='flex flex-row w-full justify-around'>
                        <LinkComponent href={`/properties?saleType=${sale.value}&category=house`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-24 m-1 hover:font-bold hover:drop-shadow-md rounded-lg'><MdVilla size={48} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor'>{dictionary.house}</span></LinkComponent>
                        <LinkComponent href={`/properties?saleType=${sale.value}&category=land`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-24 m-1 hover:font-bold hover:drop-shadow-md rounded-lg'><AiFillLayout className='hover:secondary' size={48} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor'>{dictionary.land}</span></LinkComponent>
                        <LinkComponent href={`/properties?saleType=${sale.value}&category=condo`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-24 m-1 hover:font-bold hover:drop-shadow-md rounded-lg'><FaBuilding size={48} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor'>{dictionary.condo}</span></LinkComponent>
                        <LinkComponent href={`/properties?saleType=${sale.value}&category=commercial`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-24 m-1 hover:font-bold hover:drop-shadow-md rounded-lg'><FaShop size={48} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor'>{dictionary.commercial}</span></LinkComponent>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrontSearchBar